import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Box, Text, Button } from "@chakra-ui/react";
import { FiDownload, FiPlayCircle, FiPauseCircle } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";
import { fancyTimeFormat } from "../../utils";

export default function UploadAudioBox({
    azureBlobAudioUrl,
    akamaiMp3Url,
    setValue,
    setDuration,
    setAkamaiMp3Url = () => {},
    setError,
}) {
    const [audioFile, setAudioFile] = useState("");
    const [filename, setFilename] = useState(azureBlobAudioUrl);
    const [audioDuration, setAudioDuration] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio());

    audioRef.current.oncanplay = () => {
        setAudioDuration(fancyTimeFormat(audioRef.current.duration));
    };

    useEffect(() => {
        setValue(audioFile);
    }, [audioFile]);

    useEffect(() => {
        setDuration(audioDuration);
    }, [audioDuration]);

    function onDrag(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    function validateFile(file) {
        if (!["audio/mp3", "audio/mpeg"].includes(file.type)) {
            setError(`Wrong file format. Must be MP3. The file is of type ${file.type}`);
        } else if (file.size <= 0) {
            setError("File cannot be empty");
        }
    }

    function onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const dt = evt.dataTransfer;
        const file = dt.files[0];

        validateFile(file);
        previewFile(file);
    }

    function onSelect(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const file = evt.target.files[0];

        validateFile(file);
        previewFile(file);
    }

    function previewFile(file) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
            const buffer = e.target.result;
            const fileBlob = new Blob([new Uint8Array(buffer)]);
            const url = window.URL.createObjectURL(fileBlob);
            audioRef.current.src = url;
        };
        reader.onloadend = () => {
            setFilename(file.name);
            setAudioFile(file);
        };
    }

    const handleAudioPreview = (ev) => {
        ev.preventDefault();
        if (audioRef.current.paused) {
            audioRef.current.play();
            setIsPlaying(true);
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleLinkClick = (ev) => {
        ev.preventDefault();
        if (akamaiMp3Url) {
            window.open(akamaiMp3Url, "_blank");
        }
    };

    return !audioFile && !filename && !akamaiMp3Url ? (
        <Box
            id="upload-box"
            backgroundColor="#e7eff1"
            height="150px"
            padding={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ outline: "1px dashed #93b0b3", outlineOffset: "-10px" }}
            onDragEnter={onDrag}
            onDragOver={onDrag}
            onDrop={onDrop}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <FiDownload fontSize="3em" />
                <Text marginTop={2}>
                    <label>
                        <strong style={{ cursor: "pointer" }}>Select a file</strong>
                        <input type="file" style={{ position: "fixed", marginTop: "-100em" }} onChange={onSelect} accept=".mp3" />
                    </label>{" "}
                    or drag it here.
                </Text>
            </Box>
        </Box>
    ) : (
        <Box>
            <Box display="flex" alignItems="center" position="relative">
                {akamaiMp3Url ? (
                    <>
                        <Button colorScheme="teal" size="md" variant="link" onClick={handleLinkClick} gap={2}>
                            <FiPlayCircle /> <Text>Play</Text>
                        </Button>
                        <TiDelete
                            style={{ color: "#f74545", fontSize: "1.3em", cursor: "pointer", position: "absolute", right: 0, top: 0 }}
                            onClick={() => {
                                setAudioFile("");
                                setFilename("");
                                setAkamaiMp3Url("");
                                setAudioDuration("");
                            }}
                        />
                    </>
                ) : audioDuration.length > 0 ? (
                    <Box>
                        <Text fontSize="sm" fontWeight="medium">
                            <b>Filename</b>: {filename}
                        </Text>
                        <Text fontSize="sm" fontWeight="medium">
                            <b>Duration</b>: {audioDuration}
                        </Text>
                        <Button colorScheme="teal" size="md" variant="link" onClick={handleAudioPreview} gap={2}>
                            {isPlaying ? (
                                <>
                                    <FiPauseCircle /> <Text>Pause</Text>
                                </>
                            ) : (
                                <>
                                    <FiPlayCircle /> <Text>Play</Text>
                                </>
                            )}
                        </Button>
                        <TiDelete
                            style={{ color: "#f74545", fontSize: "1.3em", cursor: "pointer", position: "absolute", right: 0, top: 0 }}
                            onClick={() => {
                                setAudioFile("");
                                setFilename("");
                                setAkamaiMp3Url("");
                                setAudioDuration("");
                            }}
                        />
                    </Box>
                ) : (
                    <Text fontSize="md" fontWeight="normal">
                        Audio is not encoded yet. Please check the{" "}
                        <Link to="/encoding-queue">
                            <Text display="inline" fontWeight="bold" color="teal">
                                Encoding Queue
                            </Text>{" "}
                        </Link>
                        .
                    </Text>
                )}
            </Box>
        </Box>
    );
}
