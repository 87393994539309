import { NavLink, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Heading, Flex } from "@chakra-ui/react";
import { Switch, Route } from "react-router-dom";

import { colors } from "../constants";
import { useMedia } from "../hooks";
import { actions } from "./redux";
import CreateVoucherCampaign from "./components/CreateVoucherCampaign";

import OverviewPage from "./OverviewPage/OverviewPage";
import UpcomingAndActivePage from "./UpcomingAndActivePage/UpcomingAndActivePage";
import InactivePage from "./InactivePage/InactivePage";
import { useEffect } from "react";
import { RootState } from "../redux/reducers/rootReducer";
import { CreateVoucherCampaignFormValues, VoucherCampaignType, VoucherCampaignTypeName } from "./redux/types";
import ValidateVoucher from "./ValidateVoucherPage/ValidateVoucherPage";
import AuthRoute from "../components/AuthRoute";
import { UserRole } from "../redux/types";

const globalState = (state: RootState) => state.global;
const manageVoucherCampaignState = (state: RootState) => state.manageVoucherCampaigns;
const manageUsersState = (state: RootState) => state.manageUsers;

export default function ManageVoucherCampaigns() {
    const dispatch = useDispatch();
    const { isLargeScreen } = useMedia();
    const { region } = useSelector(globalState);
    const { userRole } = useSelector(manageUsersState);
    const { discountTypes } = useSelector(manageVoucherCampaignState);
    const match = useRouteMatch();

    const linkStyle: any = {
        fontWeight: "bold",
        fontSize: isLargeScreen ? "20px" : "16px",
        color: colors.white,
        lineHeight: 2,
    };

    const voucherCampaignTypeNames: VoucherCampaignTypeName[] = [
        { type: VoucherCampaignType.Generic, name: "Generic | Start or restart subscription" },
        { type: VoucherCampaignType.Unique, name: "Unique | Start or restart subscription" },
    ];

    useEffect(() => {
        if (!discountTypes && (userRole === UserRole.Admin || userRole === UserRole.Editor)) {
            dispatch(actions.getVoucherCampaignDiscountTypes());
        }
    }, [userRole]);

    return (
        <Box width="100%" margin="0 auto">
            <Heading as="h1" size={"xl"} mb={8} color="white">
                Voucher campaigns
            </Heading>

            <Flex width={["100%", null, "80%"]} justifyContent="space-between" alignItems="center" paddingBottom={[8, null, 16]}>
                {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                    <>
                        <NavLink
                            to={match.path}
                            exact
                            style={linkStyle}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            Overview
                        </NavLink>
                        <NavLink
                            to={`${match.path}/upcoming-and-active`}
                            exact
                            style={linkStyle}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            Upcoming and active
                        </NavLink>
                        <NavLink
                            to={`${match.path}/inactive`}
                            exact
                            style={linkStyle}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            Inactive
                        </NavLink>
                        <NavLink
                            to={`${match.path}/validate`}
                            exact
                            style={linkStyle}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            Check voucher code
                        </NavLink>
                        <CreateVoucherCampaign
                            region={region}
                            voucherCampaignTypeNames={voucherCampaignTypeNames}
                            discountTypes={discountTypes}
                            onSubmit={(values: CreateVoucherCampaignFormValues) => dispatch(actions.createVoucherCampaign(values))}
                        />
                    </>
                )}
            </Flex>

            <Switch>
                <AuthRoute
                    exact
                    path={match.path}
                    requiredRoles={[UserRole.Admin, UserRole.Editor]}
                    component={OverviewPage}
                    redirectPath={`${match.path}/validate`}
                />
                <AuthRoute
                    exact
                    path={`${match.path}/upcoming-and-active`}
                    requiredRoles={[UserRole.Admin, UserRole.Editor]}
                    component={UpcomingAndActivePage}
                    redirectPath={`${match.path}/validate`}
                />
                <AuthRoute
                    exact
                    path={`${match.path}/inactive`}
                    requiredRoles={[UserRole.Admin, UserRole.Editor]}
                    component={InactivePage}
                    redirectPath={`${match.path}/validate`}
                />
                <Route exact path={`${match.path}/validate`} component={ValidateVoucher} />
            </Switch>
        </Box>
    );
}
