import { MultimemberUserSubscription, SUBSCRIPTION_OFFER_TYPE, SubscriptionPlan, SubscriptionProductId } from "./redux/types";

export const getIsScheduledForPlanChange = (subscriptionPlan: SubscriptionPlan): boolean => {
    return (
        subscriptionPlan?.productId !== SubscriptionProductId.CouponPlan &&
        subscriptionPlan.nextPlanId !== undefined &&
        subscriptionPlan?.nextPlanId !== null
    );
};

export function getMultimemberUserPlanName(sub: MultimemberUserSubscription) {
    switch (sub.masterSubscriptionDetails.offerDetails.subscriptionOfferType) {
        case SUBSCRIPTION_OFFER_TYPE.Duo:
            return "Premium Duo";
        case SUBSCRIPTION_OFFER_TYPE.TotalDuo:
            return "Premium Total Duo";
        default:
            return "";
    }
}
