import { useEffect, useMemo } from "react";
import { Accordion, Flex } from "@chakra-ui/react";

import SubscriptionItem from "./SubscriptionItem";
import {
    CancelSubscription,
    isBundlerUserSubscription,
    isGoogleUserSubscription,
    isMultimemberUserSubscription,
    isSubscriptionHeaderSubscription,
    MultimemberSubscriptionGuestResponse,
    Subscription,
    SubscriptionHeaderSubscription,
    SubscriptionProductId,
    SubscriptionStatus,
    isWebSubscription,
    isMultimemberMasterSubscription,
} from "../redux/types";
import { getActiveSubscriptions, getInactiveSubscriptions } from "../../utils";
import { useDispatch } from "react-redux";
import { actions } from "../redux";
import { LoginType } from "../../redux/types";
import SubscriptionGuestItem from "./SubscriptionGuest/SubscriptionGuestItem";
import { format, parseISO } from "date-fns";
import { getIsScheduledForPlanChange, getMultimemberUserPlanName } from "../utils";

interface Props {
    userId: string;
    showInactiveSubscriptions: boolean;
    subscriptions: Subscription[] | undefined;
    subscriptionGuestResponse: { [referenceSubscriptionHeaderId: number]: MultimemberSubscriptionGuestResponse } | null;
    userAccountId: string;
    selectedId: number;
    accountLoginType: LoginType;
    onSelect: () => void;
    cancelSubscription: (podcastId: number | null) => CancelSubscription;
}

function SubscriptionList({
    userId,
    showInactiveSubscriptions,
    subscriptions,
    subscriptionGuestResponse,
    userAccountId,
    selectedId,
    accountLoginType,
    onSelect,
    cancelSubscription,
}: Props) {
    const activeSubscriptions = useMemo(() => getActiveSubscriptions(subscriptions), [subscriptions]);
    const inactiveSubscriptions = useMemo(() => getInactiveSubscriptions(subscriptions), [subscriptions]);
    const dispatch = useDispatch();

    useEffect(() => {
        const subscriptionIds: number[] = (subscriptions ?? []).reduce((a, b) => {
            if (isSubscriptionHeaderSubscription(b)) {
                a.push(b.subscriptionId);
            }
            return a;
        }, [] as number[]);
        if (subscriptionIds.length) {
            dispatch(actions.startFetchSubscriptionEventsSequence(subscriptionIds, userAccountId));
            dispatch(actions.fetchPaymentTransactionsSequence(subscriptionIds));
        }

        const multimemberSubscriptionIds: number[] = (subscriptions ?? []).reduce((a, b) => {
            if (isWebSubscription(b) && isMultimemberMasterSubscription(b)) {
                a.push(b.subscriptionId);
            }
            return a;
        }, [] as number[]);
        if (userId && multimemberSubscriptionIds.length)
            dispatch(actions.fetchSubscriptionGuestHistorySequence(multimemberSubscriptionIds, userId));

        const masterSubscriptionIds: number[] = (subscriptions ?? []).reduce((a, b) => {
            if (isMultimemberUserSubscription(b)) {
                a.push(b.masterSubscriptionDetails.subscriptionId);
            }
            return a;
        }, [] as number[]);
        if (masterSubscriptionIds.length && userId) {
            dispatch(actions.fetchSubscriptionGuestHistorySequence(masterSubscriptionIds, userId));
        }
    }, [subscriptions?.length]);

    return (
        <>
            {!!subscriptions && !!subscriptions.length && (
                <>
                    {activeSubscriptions && !!activeSubscriptions?.length && (
                        <Flex direction="column" gridGap="0.5rem">
                            {activeSubscriptions.map((sub: Subscription, idx: number) => {
                                if (isMultimemberUserSubscription(sub)) {
                                    return (
                                        <Accordion allowToggle key={sub.masterSubscriptionDetails.subscriptionId}>
                                            <SubscriptionGuestItem
                                                key={idx}
                                                highlightElement={selectedId === sub.masterSubscriptionDetails.subscriptionId}
                                                onSelect={onSelect}
                                                accountLoginType={accountLoginType}
                                                subscriptionStatus={sub.masterSubscriptionDetails.subscriptionState}
                                                guestState={sub.state}
                                                isActive
                                                ownerUserId={sub.masterSubscriptionDetails.userId}
                                                subscriptionGuestHistoryList={sub.subscriptionGuestHistoryList}
                                                masterSubscriptionOfferName={getMultimemberUserPlanName(sub)}
                                            />
                                        </Accordion>
                                    );
                                }
                                const guestResponse: MultimemberSubscriptionGuestResponse | null =
                                    subscriptionGuestResponse !== null && sub.subscriptionId in subscriptionGuestResponse
                                        ? subscriptionGuestResponse[sub.subscriptionId]
                                        : null;
                                return (
                                    <Accordion allowToggle key={sub.subscriptionId}>
                                        <SubscriptionItem
                                            key={idx}
                                            highlightElement={selectedId === sub.subscriptionId}
                                            onSelect={onSelect}
                                            accountLoginType={accountLoginType}
                                            name={sub?.subscriptionPlan?.name ?? "N/A"}
                                            status={sub.subscriptionState}
                                            platform={sub.subscriptionPlatform}
                                            startDate={sub.startDate}
                                            endDate={sub.expirationDate}
                                            isActive
                                            subscriptionEvents={sub.subscriptionEvents}
                                            paymentTransactions={sub.paymentTransactions}
                                            cancelSubscription={() => cancelSubscription(sub.podcastId)}
                                            lastOrderId={isGoogleUserSubscription(sub) ? sub.lastOrderId : undefined}
                                            bundlerPartnerName={isBundlerUserSubscription(sub) ? sub.bundlerPartnerName : undefined}
                                            rewardMonthCredit={isWebSubscription(sub) ? sub.rewardMonthCredit : undefined}
                                            subscriptionId={sub.subscriptionId}
                                            userAccountId={userAccountId}
                                            scheduledOfferChangeLabel={getScheduledOfferChangeLabel(sub)}
                                            subscriptionGuestResponse={guestResponse}
                                            subscriptionGuestHistoryList={sub.subscriptionGuestHistoryList}
                                        />
                                    </Accordion>
                                );
                            })}
                        </Flex>
                    )}
                    {inactiveSubscriptions && !!inactiveSubscriptions?.length && showInactiveSubscriptions && (
                        <Flex direction="column" gridGap="0.5rem">
                            {inactiveSubscriptions.map((sub: Subscription, idx: number) => {
                                if (isMultimemberUserSubscription(sub)) {
                                    return (
                                        <Accordion allowToggle key={sub.masterSubscriptionDetails.subscriptionId}>
                                            <SubscriptionGuestItem
                                                key={idx}
                                                highlightElement={selectedId === sub.masterSubscriptionDetails.subscriptionId}
                                                onSelect={onSelect}
                                                accountLoginType={accountLoginType}
                                                subscriptionStatus={sub.masterSubscriptionDetails.subscriptionState}
                                                guestState={sub.state}
                                                isActive
                                                ownerUserId={sub.masterSubscriptionDetails.userId}
                                                subscriptionGuestHistoryList={sub.subscriptionGuestHistoryList}
                                                masterSubscriptionOfferName={getMultimemberUserPlanName(sub)}
                                            />
                                        </Accordion>
                                    );
                                }
                                const guestResponse: MultimemberSubscriptionGuestResponse | null =
                                    subscriptionGuestResponse !== null && sub.subscriptionId in subscriptionGuestResponse
                                        ? subscriptionGuestResponse[sub.subscriptionId]
                                        : null;
                                return (
                                    <Accordion allowToggle key={sub.subscriptionId}>
                                        <SubscriptionItem
                                            key={idx}
                                            highlightElement={selectedId === sub.subscriptionId}
                                            hideElement={!showInactiveSubscriptions}
                                            onSelect={onSelect}
                                            accountLoginType={accountLoginType}
                                            name={sub?.subscriptionPlan?.name ?? "N/A"}
                                            status={sub.subscriptionState}
                                            platform={sub.subscriptionPlatform}
                                            startDate={sub.startDate}
                                            endDate={sub.expirationDate}
                                            lastOrderId={isGoogleUserSubscription(sub) ? sub.lastOrderId : undefined}
                                            subscriptionEvents={sub.subscriptionEvents}
                                            paymentTransactions={sub.paymentTransactions}
                                            cancelSubscription={() => cancelSubscription(sub.podcastId)}
                                            bundlerPartnerName={isBundlerUserSubscription(sub) ? sub.bundlerPartnerName : undefined}
                                            subscriptionId={sub.subscriptionId}
                                            userAccountId={userAccountId}
                                            subscriptionGuestResponse={guestResponse}
                                            subscriptionGuestHistoryList={sub.subscriptionGuestHistoryList}
                                        />
                                    </Accordion>
                                );
                            })}
                        </Flex>
                    )}
                </>
            )}
        </>
    );
}

function getScheduledOfferChangeLabel(sub: SubscriptionHeaderSubscription): string | undefined {
    if (getIsScheduledForPlanChange(sub.subscriptionPlan)) {
        switch (sub.subscriptionPlan.nextPlanProductId) {
            case SubscriptionProductId.StudentOfferPlan:
                return "Converts to student subscription on next renewal";
            case SubscriptionProductId.DuoOfferPlan:
                return "Converts to Duo subscription on next renewal";
            case SubscriptionProductId.TotalOfferPlan:
                return "Converts to Premium Total subscription on next renewal";
            default:
                return "Converts to standard subscription on next renewal";
        }
    } else if (
        (sub.subscriptionState === SubscriptionStatus.Active || sub.subscriptionState === SubscriptionStatus.TestPeriod) &&
        isWebSubscription(sub) &&
        sub.voucher?.endDate
    ) {
        return `Voucher ends on: ${format(parseISO(sub.voucher?.endDate), "yyyy-MM-dd")}`;
    }
    return undefined;
}

export default SubscriptionList;
