import { MouseEvent, useEffect, useRef } from "react";
import { Box, Text, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Flex, Link } from "@chakra-ui/react";

import { MultimemberSubscriptionGuestState, SubscriptionGuestHistory, SubscriptionStatus } from "../../redux/types";
import { LoginType } from "../../../redux/types";
import LoginMethodLabel from "../../../components/LoginMethodLabel/LoginMethodLabel";
import { expand, pulsate } from "../../../styles/animations";
import SubscriptionGuestHistoryList from "./SubscriptionGuestHistory";

interface Props {
    highlightElement: boolean;
    hideElement?: boolean;
    accountLoginType: LoginType;
    subscriptionStatus: SubscriptionStatus;
    guestState: MultimemberSubscriptionGuestState;
    isActive?: boolean;
    ownerUserId: string;
    subscriptionGuestHistoryList: SubscriptionGuestHistory[] | undefined;
    onSelect: () => void;
    masterSubscriptionOfferName: string;
}

function SubscriptionGuestItem({
    highlightElement,
    hideElement = false,
    onSelect,
    accountLoginType,
    subscriptionStatus,
    guestState,
    isActive = false,
    ownerUserId,
    subscriptionGuestHistoryList,
    masterSubscriptionOfferName,
}: Props) {
    const accordionItemRef = useRef<HTMLDivElement>(null);
    const handleAccountClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        onSelect();
    };
    const { subscriptionStatusName, subscriptionStatusColor } = getSubscriptionStatusNameAndStatusColor(
        subscriptionStatus,
        guestState
    );

    useEffect(() => {
        if (highlightElement && accordionItemRef.current) {
            accordionItemRef.current.scrollIntoView({ block: "start", behavior: "smooth", inline: "start" });
        }
    }, [highlightElement]);

    return (
        <>
            <AccordionItem
                ref={accordionItemRef}
                scrollMarginTop="1rem"
                padding="0"
                borderWidth="1px"
                borderStyle="solid"
                borderColor={highlightElement ? "#e4d652" : "#e0e0e0"}
                rounded="1rem"
                animation={!hideElement && !isActive ? `${expand} 250ms forwards ease-out` : undefined}
                bgColor="#eee"
                _hover={{
                    bgColor: "#eaeaea",
                }}
            >
                <AccordionButton
                    padding="1rem"
                    rounded="1rem"
                    display="flex"
                    gridGap="1rem"
                    justifyContent="space-between"
                    animation={highlightElement ? `${pulsate} 500ms infinite` : undefined}
                    _focus={{ outline: "none" }}
                    _hover={{
                        bgColor: "none",
                    }}
                >
                    <Flex flex="1" minWidth="fit-content" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Box padding="0.15rem 1rem" bgColor={subscriptionStatusColor} rounded="3rem">
                            <Text as="span" display="block" fontSize="0.875rem">
                                {subscriptionStatusName}
                            </Text>
                        </Box>
                    </Flex>

                    <Flex flex="3" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Name
                        </Text>
                        <Text fontWeight={600} textAlign="left" style={{ textWrap: "balance" } as any}>
                            Guest - {masterSubscriptionOfferName}
                        </Text>
                    </Flex>

                    <Flex flex="3" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Owner User
                        </Text>
                        <Link fontWeight={600} href={`/users/${ownerUserId}`}>
                            <Text as="span" fontSize="0.75rem" color={"#256199"} textTransform="uppercase">
                                {ownerUserId}
                            </Text>
                        </Link>
                    </Flex>

                    <Flex flex="2" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Account
                        </Text>
                        <Box
                            onClick={handleAccountClick}
                            padding="0"
                            background="none"
                            height="auto"
                            _hover={{
                                boxShadow: "none",
                            }}
                            _focus={{
                                outline: "none",
                                boxShadow: "none",
                            }}
                        >
                            <Box
                                color="#256199"
                                cursor="pointer"
                                _hover={{
                                    textDecoration: "underline",
                                }}
                            >
                                <LoginMethodLabel loginType={accountLoginType} />
                            </Box>
                        </Box>
                    </Flex>

                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                    <Flex gap={"2rem"} flexDirection={"column"}>
                        {subscriptionGuestHistoryList && (
                            <SubscriptionGuestHistoryList
                                subscriptionGuestHistoryList={subscriptionGuestHistoryList}
                                includeUserIdColumn={false}
                            />
                        )}
                    </Flex>
                </AccordionPanel>
            </AccordionItem>
        </>
    );
}

function getSubscriptionStatusNameAndStatusColor(
    subscriptionStatus: SubscriptionStatus,
    guestState?: MultimemberSubscriptionGuestState
) {
    if (guestState === MultimemberSubscriptionGuestState.Inactive)
        return { subscriptionStatusName: SubscriptionStatus[SubscriptionStatus.Inactive], subscriptionStatusColor: "gray.400" };
    if (subscriptionStatus === SubscriptionStatus.Inactive || subscriptionStatus === SubscriptionStatus.PaymentProblem)
        return { subscriptionStatusName: SubscriptionStatus[SubscriptionStatus.Inactive], subscriptionStatusColor: "gray.400" };

    return { subscriptionStatusName: SubscriptionStatus[SubscriptionStatus.Active], subscriptionStatusColor: "green.300" };
}

export default SubscriptionGuestItem;
