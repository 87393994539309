import { Box, Button, Flex, Text, useToast, UseToastOptions } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";

import { isMultimemberUserSubscription, isSubscriptionHeaderSubscription, UserAccount } from "../../redux/types";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import LoginMethodImg from "../../../components/LoginMethodImg/LoginMethodImg";
import { getActiveSubscriptions } from "../../../utils";
import { LoginType, LOGIN_TYPE, PodmeColor } from "../../../redux/types";
import { pulsate } from "../../../styles/animations";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
import { getMultimemberUserPlanName } from "../../utils";

interface Props {
    disableInactiveSubscriptions: boolean;
    isDeletingUser: boolean;
    accountsList: UserAccount[];
    userId: string;
    selectedId: string;
    onSelect: (id: number) => void;
    deleteUserFn: (id: string) => void;
}

const defaultAccountErrorToastConfig: UseToastOptions = {
    status: "error",
    title: `Delete account failed`,
    duration: 5000,
    isClosable: true,
    position: "top",
    variant: "left-accent",
};

type DeleteUserFnArgs = {
    accountType: LoginType;
    isMasterAccount: boolean;
    hasActiveSubscriptions: boolean;
    userAccountId: string;
};

function LinkedAccounts({
    disableInactiveSubscriptions,
    isDeletingUser,
    accountsList,
    userId,
    selectedId,
    onSelect,
    deleteUserFn,
}: Props) {
    const accountItemRef = useRef<HTMLDivElement>(null);
    const toast = useToast();

    const [deleteAlertOpened, setDeleteAlertOpened] = useState(false);
    const [userAccountIdToDelete, setUserAccountIdToDelete] = useState<string>("");

    const sortedAccountsList = useMemo(
        () =>
            accountsList.sort((a, b) => {
                if (a.userAccountId === userId) return -1;
                if (b.userAccountId === userId) return 1;
                return 0;
            }),
        [accountsList, userId]
    );

    const handleDeleteConfirmed = useCallback((userAccountId: string) => {
        deleteUserFn(userAccountId);
    }, []);

    const handleDeleteUser = useCallback(
        ({ accountType, isMasterAccount, hasActiveSubscriptions, userAccountId }: DeleteUserFnArgs) => {
            if (
                accountType === LOGIN_TYPE.SchibstedSE ||
                accountType === LOGIN_TYPE.SchibstedNO ||
                accountType === LOGIN_TYPE.SchibstedFI
            ) {
                toast({
                    ...defaultAccountErrorToastConfig,
                    description: `You can't delete a Schibsted Account!`,
                });
            } else if (isMasterAccount && accountsList.length > 1) {
                toast({
                    ...defaultAccountErrorToastConfig,
                    description: `You can't delete the Master Account! You can only delete it after you have removed all other linked accounts!`,
                });
            } else if (hasActiveSubscriptions) {
                toast({
                    ...defaultAccountErrorToastConfig,
                    description: `You can't delete an account with an active subscription!`,
                });
            } else {
                setUserAccountIdToDelete(userAccountId);
                setDeleteAlertOpened(true);
            }
        },
        [accountsList]
    );

    return (
        <Flex as="section" gridGap="0.5rem" wrap="wrap">
            {sortedAccountsList.map((account) => {
                const isMasterAccount = account.userAccountId === userId;
                const hasSubscription = !!account.subscriptions.length;
                const activeSubscriptions = getActiveSubscriptions(account.subscriptions);
                const hasActiveSubscriptions = !!activeSubscriptions.length;
                const highlightElement = selectedId === account.userAccountId;

                highlightElement && accountItemRef?.current?.scrollIntoView({ block: "start", behavior: "smooth", inline: "start" });

                return (
                    <Fragment key={account.userAccountId}>
                        <Flex
                            direction="column"
                            gridGap="0.5rem"
                            width="100%"
                            fontSize="0.875rem"
                            position="relative"
                            zIndex="1"
                            ref={accountItemRef}
                            _after={{
                                content: isMasterAccount ? '""' : undefined,
                                position: "absolute",
                                top: "0",
                                lineHeight: "1.4",
                                fontSize: "0.5rem",
                                fontWeight: "700",
                                left: "-1rem",
                                height: "100%",
                                width: "5rem",
                                borderRadius: "1rem 0 0 1rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#f2e24b",
                                border: "1px solid #e4d652",
                                zIndex: "-1",
                            }}
                            _before={{
                                content: isMasterAccount ? '"MASTER"' : undefined,
                                position: "absolute",
                                top: "50%",
                                lineHeight: "1.4",
                                fontSize: "0.5rem",
                                fontWeight: "700",
                                left: "-1.45rem",
                                zIndex: "0",
                                transform: "rotate(-90deg) translateX(22%)",
                            }}
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gridGap="1rem"
                                padding="1rem"
                                backgroundColor="#eee"
                                zIndex="2"
                                borderRadius="1rem"
                                borderWidth="1px"
                                borderStyle="solid"
                                borderColor={highlightElement || isMasterAccount ? "#e4d652" : "#e0e0e0"}
                                animation={highlightElement ? `${pulsate} 500ms infinite` : undefined}
                                _hover={{
                                    bgColor: isMasterAccount ? "#f0edda" : "#eaeaea",
                                }}
                            >
                                <Box flex="2" flexBasis="3rem" minWidth="5rem">
                                    <Box maxWidth="fit-content">
                                        <LoginMethodImg loginType={account.loginType} />
                                    </Box>
                                </Box>
                                <Flex flex="8" justifyContent="space-between" direction="column" gridGap="0.25rem">
                                    <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                        User Account ID
                                    </Text>
                                    <Text fontWeight="700" fontSize="1rem">
                                        {account.userAccountId}
                                    </Text>
                                </Flex>
                                <Flex flex="5" justifyContent="space-between" direction="column" gridGap="0.25rem">
                                    <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                        Subscription
                                    </Text>
                                    <Box fontWeight="700" fontSize="1rem">
                                        {hasSubscription ? (
                                            <Flex direction="column" gridGap="0.25rem">
                                                {account.subscriptions.map((sub) => {
                                                    const isSubscriptionActive = !!activeSubscriptions.find((activeSub) => {
                                                        const activeSubscriptionHeader =
                                                            isSubscriptionHeaderSubscription(sub) &&
                                                            isSubscriptionHeaderSubscription(activeSub) &&
                                                            activeSub.subscriptionId === sub.subscriptionId;
                                                        const activeMultimemberSubscription =
                                                            isMultimemberUserSubscription(sub) &&
                                                            isMultimemberUserSubscription(activeSub) &&
                                                            sub.masterSubscriptionDetails.subscriptionId ===
                                                                activeSub.masterSubscriptionDetails.subscriptionId;
                                                        return activeSubscriptionHeader || activeMultimemberSubscription;
                                                    });

                                                    const buttonDisabled = disableInactiveSubscriptions && !isSubscriptionActive;
                                                    const subscriptionId = isSubscriptionHeaderSubscription(sub)
                                                        ? sub.subscriptionId
                                                        : sub.masterSubscriptionDetails.subscriptionId;
                                                    const subscriptionPlanName = isSubscriptionHeaderSubscription(sub)
                                                        ? sub?.subscriptionPlan?.name
                                                        : isMultimemberUserSubscription(sub)
                                                        ? `Guest - ${getMultimemberUserPlanName(sub)}`
                                                        : "N/A";

                                                    return (
                                                        <Button
                                                            key={subscriptionId}
                                                            justifyContent="flex-start"
                                                            isDisabled={buttonDisabled}
                                                            onClick={() => !buttonDisabled && onSelect(subscriptionId)}
                                                            padding="0"
                                                            background="none"
                                                            height="auto"
                                                            maxWidth="fit-content"
                                                            _hover={{
                                                                boxShadow: "none",
                                                            }}
                                                            _focus={{
                                                                outline: "none",
                                                                boxShadow: "none",
                                                            }}
                                                        >
                                                            <Text
                                                                as="span"
                                                                textAlign="left"
                                                                style={{ textWrap: "balance" } as any}
                                                                key={subscriptionId}
                                                                color="#256199"
                                                                _hover={
                                                                    !buttonDisabled
                                                                        ? { textDecoration: "underline", cursor: "pointer" }
                                                                        : undefined
                                                                }
                                                            >
                                                                {subscriptionPlanName}
                                                            </Text>
                                                        </Button>
                                                    );
                                                })}
                                            </Flex>
                                        ) : (
                                            <Text as="span">No</Text>
                                        )}
                                    </Box>
                                </Flex>
                                <Button
                                    onClick={() =>
                                        handleDeleteUser({
                                            accountType: account.loginType,
                                            isMasterAccount,
                                            hasActiveSubscriptions,
                                            userAccountId: account.userAccountId,
                                        })
                                    }
                                    padding="0.75rem"
                                    bgColor="transparent"
                                    rounded="50%"
                                    _hover={{
                                        bgColor: PodmeColor.Silver + "20",
                                    }}
                                >
                                    <MdDelete opacity="0.5" size="1.125rem" />
                                </Button>
                            </Flex>
                        </Flex>
                    </Fragment>
                );
            })}
            <DeleteUserModal
                isOpen={deleteAlertOpened}
                isDeletingUser={isDeletingUser}
                deleteUserFn={handleDeleteConfirmed}
                setDeleteAlertOpened={setDeleteAlertOpened}
                userAccountId={userAccountIdToDelete}
            />
        </Flex>
    );
}

export default LinkedAccounts;
